import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getInstructorData } from "../../../../services/operations/profileAPI";
import { fetchInstructorCourses } from "../../../../services/operations/courseDetailsAPI";
import { Link } from "react-router-dom";
import { setLoading } from "../../../../slices/profileSlice";
import InstructorChart from "./InstructorChart";

const Instructor = () => {
  const [loading, steLoading] = useState(false);
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const [instructorData, setInstructorData] = useState(null);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const getCourseDataWithStats = async () => {
      steLoading(true);
      const instructorApiData = await getInstructorData(token);
      const result = await fetchInstructorCourses(token);
      // console.log("hello ", result);

      if (result) {
        setCourses(result);
      }
      if (instructorApiData) {
        setInstructorData(instructorApiData);
      }
      steLoading(false);
    };
    // console.log("hello user : ", user?.firstName);
    getCourseDataWithStats();
  }, []);

  const totalAmount = instructorData?.reduce(
    (acc, curr) => acc + curr.totalAmountGenerated,
    0
  );
  const totalStudents = instructorData?.reduce(
    (acc, curr) => acc + curr.totalStudentsEnrolled,
    0
  );

  return (
    <div className=" gap-y-8  w-[80vw] mx-auto flex  items-center flex-col ">
      <div className="space-y-2">
        {" "}
        <div>
          <h1 className="text-2xl font-bold text-richblack-5">
            Hi {user?.firstName} 👋
          </h1>
          <p className="font-medium text-richblack-200">
            Let's start something new
          </p>
        </div>
        <div>
          {loading ? (
            <div>Loading..</div>
          ) : courses.length > 0 ? (
            <div className="my-4 flex w-[50rem] h-[450px] space-x-4">
              {/* Render chart / graph */}
              {totalAmount > 0 || totalStudents > 0 ? (
                <InstructorChart courses={instructorData} />
              ) : (
                <div className="flex-1 rounded-md bg-richblack-800 p-6">
                  <p className="text-lg font-bold text-richblack-5">
                    Visualize
                  </p>
                  <p className="mt-4 text-xl font-medium text-richblack-50">
                    Not Enough Data To Visualize
                  </p>
                </div>
              )}
              <div className="flex min-w-[250px] flex-col rounded-md bg-richblack-800 p-6">
                <p className="text-lg font-bold text-richblack-5">Statistics</p>
                <div className="mt-4 space-y-4">
                  <div>
                    <p className="text-lg text-richblack-200">Total Courses</p>
                    <p className="text-3xl font-semibold text-richblack-50">
                      {courses.length}
                    </p>
                  </div>
                  <div>
                    <p className="text-lg text-richblack-200">Total Students</p>
                    <p className="text-3xl font-semibold text-richblack-50">
                      {totalStudents}
                    </p>
                  </div>
                  <div>
                    <p className="text-lg text-richblack-200">Total Income</p>
                    <p className="text-3xl font-semibold text-richblack-50">
                      Rs. {totalAmount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-20 rounded-md bg-richblack-800 p-6 py-20">
              <p className="text-center text-2xl font-bold text-richblack-5">
                You have not created any courses yet
              </p>
              <Link to={"/dashboard/add-course"}>
                {" "}
                <p className="mt-1 text-center text-lg font-semibold text-yellow-50">
                  Create a course
                </p>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div>
        {" "}
        {/* Render 3 courses */}
        <div className="flex items-center justify-between">
          <p className="text-lg font-bold text-richblack-5">Your Courses</p>
          <Link to="/dashboard/my-courses">
            <p className="text-xs font-semibold text-yellow-50">View All</p>
          </Link>
        </div>
        <div>
          {courses.slice(0, 3).map((course, key) => (
            <div className="w-1/3">
              <img
                className="h-[201px] w-full rounded-md object-cover"
                src={course.thumbnail}
                alt="img"
              />
              <div className="mt-3 w-full">
                <p className="text-sm font-medium text-richblack-50">
                  {course.courseName}
                </p>
                {/* <div>
                  <p>{course.studentsEnrolled.length} students</p>
                  <p> | </p>
                  <p> Rs {course.price}</p>
                </div> */}{" "}
                <div className="mt-1 flex items-center space-x-2">
                  <p className="text-xs font-medium text-richblack-300">
                    {course.studentsEnrolled.length} students
                  </p>
                  <p className="text-xs font-medium text-richblack-300">|</p>
                  <p className="text-xs font-medium text-richblack-300">
                    Rs. {course.price}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Instructor;
